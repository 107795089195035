import React from 'react'
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const Services_10 = () => {
  return (
    <div className="services-container">
      <Container>
        <section className="services-header">
          <h1>
            Advancing Your Business with Cutting-Edge Automation and Robotics
            Services
          </h1>
          <p>
            In today’s competitive business environment, efficiency, precision,
            and innovation are crucial for maintaining a competitive edge. Our
            company specializes in providing advanced automation and robotics
            services that are tailored to optimize operations, boost
            productivity, and foster growth across various industries. Whether
            you operate in manufacturing, logistics, healthcare, or other
            sectors, our comprehensive expertise in automation and robotics is
            designed to revolutionize your business processes. Here’s an
            in-depth exploration of our services and how they can benefit your
            organization.
          </p>
        </section>
        <section className="services-content">
          <h2>Automation Services:</h2>
          <ul>
            <li>
              <h2>
                Process Automation:{" "}
                <p>
                  Our process automation solutions streamline business
                  operations by automating repetitive and time-consuming tasks.
                  Utilizing state-of-the-art technologies like robotic process
                  automation (RPA), we enhance efficiency, reduce errors, and
                  allow your workforce to focus on strategic initiatives.
                </p>
              </h2>
            </li>
          </ul>
          <h2>Manufacturing Automation </h2>
          <ul>
            <p>
            In manufacturing, automation plays a pivotal role in enhancing production speed, consistency, and quality. Our tailored manufacturing automation solutions integrate automated assembly lines, robotic arms, and CNC machines to optimize operations and deliver significant productivity gains.
            </p>
          </ul>
          <h2>Logistics and Supply Chain Automation:  </h2>
          <ul>
            <p>
            Efficient logistics and supply chain management are critical for timely deliveries and cost control. Our automation services encompass warehouse management systems (WMS), automated guided vehicles (AGVs), and automated storage and retrieval systems (AS/RS). These solutions improve inventory accuracy, reduce labour costs, and streamline overall logistics operations.
            </p>
          </ul>
          <h2>Office Automation:  </h2>
          <p>
          Our office automation services focus on streamlining administrative tasks such as data entry, scheduling, and document management. By implementing intelligent automation tools, we enhance workflow efficiency, minimize errors, and boost productivity in office environments.
          </p>
          <h2>Robotics Services:</h2>
          <br/>
          <h2>Industrial Robotics:  </h2>
          <p>
          We specialize in deploying advanced industrial robotics solutions that revolutionize manufacturing processes. Our industrial robots excel in tasks such as welding, painting, assembly, and material handling, delivering unparalleled precision and efficiency to production lines.
          </p>

          <h2>Collaborative Robots (Cobots): </h2>
          <p>
          Collaborative robots, or cobots, are designed to work alongside human operators, enhancing productivity and safety. Our cobots are equipped with advanced sensors and AI algorithms, enabling safe interaction and efficient collaboration in tasks such as packaging, inspection, and assembly.
          </p>

          <h2>Service Robots: </h2>
          <p>
          In sectors like healthcare, hospitality, and retail, our service robots offer innovative solutions for improving customer service and operational efficiency. Capable of tasks such as patient care, cleaning, and customer assistance, these robots enhance service delivery while reducing operational costs.
          </p>
          <h2>Custom Robotics Solutions </h2>
          <p>
          Recognizing that each business has unique requirements, we offer custom robotics solutions tailored to specific needs. From initial design and prototyping to final deployment and maintenance, our comprehensive approach ensures that our robotics solutions align perfectly with your business objectives.
          </p>
          <h1>Benefits of Our Automation and Robotics Services:</h1>
          <h2>Enhanced Efficiency: </h2>
          <p>
          Automation and robotics streamline operations, reducing cycle times and increasing throughput. This efficiency boost translates into higher productivity and optimized resource utilization.
          </p>
          <h2>Improved Precision and Quality:  </h2>
          <p>
          Robots and automated systems perform tasks with exceptional accuracy and consistency, minimizing errors and defects. This results in improved product quality, customer satisfaction, and operational excellence.
          </p>

          <h2>Cost Savings:  </h2>
          <p>
          By automating repetitive tasks and optimizing workflows, businesses can significantly reduce labour costs and operational expenses. The initial investment in automation yields long-term savings through improved efficiency and reduced waste.
          </p>
          <h2>Scalability:  </h2>
          <p>
          Our automation and robotics solutions are designed to scale with your business, accommodating growth and evolving operational needs seamlessly. This scalability ensures that your business remains agile and competitive in dynamic market conditions.
          </p>
          <h2>Safety and Compliance:  </h2>
          <p>
          Automation and robotics enhance workplace safety by automating hazardous tasks and adhering to stringent safety standards. By mitigating risks and ensuring compliance, businesses create a safer work environment for employees.
          </p>
          
          <h2>Data-Driven Insights </h2>
          <p>
          Automated systems generate valuable data that can be analyzed to gain actionable insights into operational performance and efficiency. This data-driven approach empowers businesses to make informed decisions and continuously improve processes.
          </p>
          <h2>Future-Proofing You’re Business:</h2>
          <p>
          Investing in automation and robotics is an investment in future-proofing your business. As technology advances, automation becomes increasingly integral to maintaining a competitive advantage. Our services are designed to leverage the latest innovations and ensure your business remains at the forefront of industry trends.
          </p>
          <h2>Why Choose Us: </h2>
          <p>
          Partnering with us means gaining access to a team of automation and robotics experts committed to delivering exceptional results. We bring extensive industry knowledge, technical expertise, and a passion for innovation to every project. Let us help you unlock new levels of efficiency, productivity, and growth with our advanced automation and robotics solutions.
          </p>
          
        </section>
        <section className="contact-section">
          <h2>Contact Us</h2>
          <Row>
            <Col md={4} className="contact-info">
              <h3>Address</h3>
              <p>
                1234 Embedded Systems Lane
                <br />
                Tech City, TX 12345
              </p>
            </Col>
            <Col md={4} className="contact-info">
              <h3>Phone</h3>
              <p>(123) 456-7890</p>
            </Col>
            <Col md={4} className="contact-info">
              <h3>Email</h3>
              <p>
                <a href="mailto:info@example.com">info@example.com</a>
              </p>
            </Col>
          </Row>
          <Form className="contact-form">
            <h3>Get in Touch</h3>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                required
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Your message"
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Send Message
            </Button>
          </Form>
        </section>
      </Container>
    </div>
  )
}

export default Services_10