import React from 'react';
import { Container } from 'react-bootstrap';
import AboutBImg from "../img/breadcrumb-thumb.png";
import './MainAboutUs.css'; // Import the CSS file
import OurGoal from '../components/MainAboutComponents/OurGoal';
import Achieve from '../components/MainAboutComponents/Achieve';
import WorkProcess from '../components/MainAboutComponents/WorkProcess';
import ClientsTestimonial from '../components/MainAboutComponents/ClientsTestimonial';
import CareerOpportunities from '../components/MainAboutComponents/CareerOpportunities';

const MainAboutUs = () => {
  return (
    <div className='main-about-us'>
    <div className='about-banner'>
      <Container>
        <div className='flex-container'>
          <div className='text-container'>
            <h1 className='heading'>About Us</h1>
          </div>
          <div className='image-container'>
            <img className='image' src={AboutBImg} alt='About Us' />
          </div>
        </div>
      </Container>
    </div>
      <OurGoal/>
      <Achieve/>
      <WorkProcess/>
      <ClientsTestimonial/>
      <CareerOpportunities/>
    </div>
  );
}

export default MainAboutUs;
