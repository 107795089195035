import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa"; // Import icons
import "./OurGoal.css"; // Import the CSS file

const OurGoal = () => {
  return (
    <Container className="OurGoal-container">
      <Row className="haddings-goal">
        <Col xs={12} md={6} className="OurGoal-title">
          <h5>Our Goal</h5>
          <h1>Partnering Business for Success</h1>
        </Col>
        <Col xs={12} md={6} className="OurGoal-content">
          <p>
          Techsodes Pvt. Ltd. is actively seeking strategic partnerships to enhance our market presence, expand our product offerings, and drive sustainable growth.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} className="button-col">
          <Button>Biography</Button>
          <Button>Education</Button>
          <Button>Experience</Button>
          <Button>Biography</Button>
        </Col>
        <Col xs={12} md={4}>
          <div className="list_1-o" >
            <h3>Performance Enhancement Partners</h3>
            <h4>Strategic Solutions Pro</h4>
            <h5><FaCheck /> Success Accelerators</h5>
            <h5><FaCheck /> Started Politician Club</h5>
          </div>
          <div className="list_2-o">
            <h3>Performance Enhancement Partners</h3>
            <h4>Strategic Solutions Pro</h4>
            <h5><FaCheck /> Success Accelerators</h5>
            <h5><FaCheck /> Started Politician Club</h5>
          </div>
        </Col>
        <Col xs={12} md={4} >
          <div className="list_3">
            <h3>Performance Enhancement Partners</h3>
            <h4>Strategic Solutions Pro</h4>
            <h5><FaCheck /> Success Accelerators</h5>
            <h5><FaCheck /> Started Politician Club</h5>
          </div>
          <div className="list_4">
            <h3>Performance Enhancement Partners</h3>
            <h4>Strategic Solutions Pro</h4>
            <h5><FaCheck /> Success Accelerators</h5>
            <h5><FaCheck /> Started Politician Club</h5>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OurGoal;
