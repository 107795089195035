import React from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import "./Header.css";
import logo from "../img/Logo__6_-removebg-preview-removebg-preview.png"
const Header = () => {
  return (
    <Navbar bg="white" variant="light" expand="lg" className="custom-navbar">
      <Container>
        <Navbar.Brand href="/" className="navbar-brand">
           <img src={logo}/>
          
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggle-navbar-button" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/main-about-us">About Us</Nav.Link>

            {/* Dropdown for Services */}
            <NavDropdown title="Services" id="services-dropdown" className="services-dropdown">
              <NavDropdown.Item href="/Services_1">Embedded Systems Design R&D Firmware Development Services</NavDropdown.Item>
              <NavDropdown.Item href="/Services_2">Software Development Services</NavDropdown.Item>
              <NavDropdown.Item href="/Services_3">Automation and Robotics Services</NavDropdown.Item>
              <NavDropdown.Item href="/Services_4">Hardware schematic and PCB design services</NavDropdown.Item>
              <NavDropdown.Item href="/Services_5">Sales and Custom Manufacturing services</NavDropdown.Item>
              <NavDropdown.Item href="/Services_6">Branding With Digital Marketing Services</NavDropdown.Item>
              <NavDropdown.Item href="/Services_7">GPS Tracking and IoT Services</NavDropdown.Item>
              <NavDropdown.Item href="/Services_8">Comprehensive Door Lock Systems Services</NavDropdown.Item>
              <NavDropdown.Item href="/Services_9">3D Modelling and Printing Services</NavDropdown.Item>
              <NavDropdown.Item href="/Services_10">Plastic and Wood Laser Cutting Services</NavDropdown.Item>
              <NavDropdown.Item href="/Services_11">Digital Marketing</NavDropdown.Item>
              <NavDropdown.Item href="/services_12">Web Developer</NavDropdown.Item>


            </NavDropdown>

            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="/contact" className="btn-contact">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
