import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CareerImg from "../../img/wcu-thumb-1-1.png";
import CareerB2 from "../../img/1 (1).png";
import CareerSvg_1 from "../../img/wcu-icon_2-1.svg";
import CareerSvg_2 from "../../img/wcu-icon_2-2.svg";
import CareerSvg_3 from "../../img/wcu-icon_2-3.svg";
import CareerSvg_4 from "../../img/wcu-icon_2-4.svg";
import "./CareerOpportunities.css"; // Import the CSS file

const CareerOpportunities = () => {
  return (
    <div className="career-opportunities-section">
      <h5 className="section-title">Career Opportunities</h5>
      <h1 className="section-heading">Your Business Goals with Confidence</h1>
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="image-col">
          <img src={CareerB2} alt="Career Image" />
            <img
              src={CareerImg}
              alt="Career Opportunities"
              className="career-image"
            />
          </Col>
          <Col md={6} className="cards-col">
            <div className="cards">
              <div className="card">
                <img
                  src={CareerSvg_1}
                  alt="Network Infrastructure Solutions"
                  className="card-icon"
                />
                <h4>Network Infrastructure Solutions</h4>
                <h6>IT Technology is a category encompassing</h6>
              </div>
              <div className="card">
                <img
                  src={CareerSvg_2}
                  alt="Network Infrastructure Solutions"
                  className="card-icon"
                />
                <h4>Network Infrastructure Solutions</h4>
                <h6>IT Technology is a category encompassing</h6>
              </div>
            </div>
            <div className="cards">
              <div className="card">
                <img
                  src={CareerSvg_3}
                  alt="Network Infrastructure Solutions"
                  className="card-icon"
                />
                <h4>Network Infrastructure Solutions</h4>
                <h6>IT Technology is a category encompassing</h6>
              </div>
              <div className="card">
                <img
                  src={CareerSvg_4}
                  alt="Network Infrastructure Solutions"
                  className="card-icon"
                />
                <h4>Network Infrastructure Solutions</h4>
                <h6>IT Technology is a category encompassing</h6>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CareerOpportunities;
