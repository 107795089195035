import React, { useState } from "react";
import Event from "../img/event-thumb-2-1.png";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./UpcomingEvents.css";

const AccordionItem = ({ title, date, children, isOpen, onClick }) => {
  return (
    <div className="accordion">
      <div className="accordion-h" onClick={onClick}>
        {isOpen ? (
          <FaChevronUp className="accordion-icon" />
        ) : (
          <FaChevronDown className="accordion-icon" />
        )}
        <div className="accordion-header">
          <span className="accordion-title">{title}</span>
          <span className="accordion-date">{date}</span>
        </div>
      </div>
      {isOpen && <div className="accordion-body">{children}</div>}
    </div>
  );
};

const CustomAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="custom-accordion">
      <AccordionItem
        title="Innovate Xpo"
        date="March 5, 2024"
        isOpen={activeIndex === 0}
        onClick={() => handleAccordionClick(0)}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </AccordionItem>
      <AccordionItem
        title="Future Forward Forum"
        date="April 12, 2024"
        isOpen={activeIndex === 1}
        onClick={() => handleAccordionClick(1)}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </AccordionItem>
      <AccordionItem
        title="Tech Innovators Summit"
        date="May 15, 2024"
        isOpen={activeIndex === 2}
        onClick={() => handleAccordionClick(2)}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </AccordionItem>
    </div>
  );
};

const UpcomingEvents = () => {
  return (
    <div className="upcoming-main">
      <div className="content-container">
        <div className="text-section">
          <h5>Upcoming Events</h5>
          <h1>Unlocking Potential Maximize Growth</h1>
          <CustomAccordion />
        </div>
        <div className="image-section">
          <img src={Event} alt="Event" />
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvents;
