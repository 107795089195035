import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "./LatestBlog.css";
import {
  FaArrowLeft,
  FaArrowRight,
  FaUser,
  FaCalendarAlt,
} from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Blog_1 from "../img/3-1 (1).png";
import Blog_2 from "../img/3-2 (1).png";
import Blog_3 from "../img/3-3 (1).png";

const LatestBlog = () => {
  const content = [
    {
      src: Blog_1,
      alt: "Slide 1",
      work: "Admin",
      date: "October 19, 2024",
      title: "Hard Work Always Brings You Success",
    },
    {
      src: Blog_2,
      alt: "Slide 2",
      work: "Admin",
      date: "October 19, 2024",
      title: "Transformation Strategy Advisors Operat",
    },
    {
      src: Blog_3,
      alt: "Slide 3",
      work: "Admin",
      date: "October 19, 2024",
      title: "Growth and Innovation the Consultants",
    },
  ];

  const sliderRef = React.createRef();

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: (
      <FaArrowRight
        className="slider-arrow next-arrow"
        color={"#14514e"}
        onClick={nextSlide}
      />
    ),
    prevArrow: (
      <FaArrowLeft
        className="slider-arrow prev-arrow"
        color={"#14514e"}
        onClick={prevSlide}
      />
    ),
  };

  return (
    <Container className="latest-blog">
      <Row className="header">
        <Col className="header-text">
          <h5>Latest Blog</h5>
          <h1>Strategies Tailored to Elevate</h1>
        </Col>
        <Col className="header-icons" style={{ textAlign: 'right' }}>
          <FaArrowLeft className="outside-arrow" onClick={prevSlide} />
          <FaArrowRight className="outside-arrow" onClick={nextSlide} />
        </Col>
      </Row>

      <Row>
        <Col>
          <Slider ref={sliderRef} {...settings}>
            {content.map((item, index) => (
              <div key={index} className="slide-item">
                <img src={item.src} alt={item.alt} className="slide-image" />
                <div className="text-content">
                  <h5>
                    <FaUser className="icon" /> {item.work}
                  </h5>
                  <h6>
                    <FaCalendarAlt className="icon" /> {item.date}
                  </h6>
                </div>
                <div>
                  <h3>{item.title}</h3>
                  <button>Read more</button>
                </div>
              </div>
            ))}
          </Slider>
        </Col>
      </Row>
    </Container>
  );
};

export default LatestBlog;
