import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./LatestPortfolio.css"; // Import the CSS file

import Portfolio_1 from "../img/3-1 (1).png";
import Portfolio_2 from "../img/3-2 (1).png";
import Portfolio_3 from "../img/3-3 (1).png";
import Portfolio_4 from "../img/3-4 (1).png";

const LatestPortfolio = () => {
  return (
    <Container className="latest-portfolio">
      <Row>
        <Col>
          <h5>Latest Portfolio</h5>
          <h1>Guiding Businesses Toward Success</h1>
        </Col>
        <Col className="text-end">
          <Button>View more</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="portfolio-item">
            <img src={Portfolio_1} alt="Portfolio Item 1" />
            <div className="hover-overlay">
              <Button className="overlay-button">Experience</Button>
              <h2> Your Business Goals with the Confidence</h2>
              <p>We have been operating for over a decade, providing top-notch services to our clients and building</p>
            </div>
          </div>
        </Col>
        <Col>
          <div className="portfolio-item">
            <img src={Portfolio_2} alt="Portfolio Item 2" />
            <div className="hover-overlay">
              <Button className="overlay-button">Experience</Button>
              <h2> Your Business Goals with the Confidence</h2>
              <p>We have been operating for over a decade, providing top-notch services to our clients and building</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="portfolio-item">
            <img src={Portfolio_3} alt="Portfolio Item 3" />
            <div className="hover-overlay">
              <Button className="overlay-button">Experience</Button>
              <h2> Your Business Goals with the Confidence</h2>
              <p>We have been operating for over a decade, providing top-notch services to our clients and building</p>
            </div>
          </div>
        </Col>
        <Col>
          <div className="portfolio-item">
            <img src={Portfolio_4} alt="Portfolio Item 4" />
            <div className="hover-overlay">
              <Button className="overlay-button">Experience</Button>
              <h2> Your Business Goals with the Confidence</h2>
              <p>We have been operating for over a decade, providing top-notch services to our clients and building</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LatestPortfolio;
