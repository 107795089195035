import React from "react";
import "./Achieve.css"; // Import the CSS file

const Achieve = () => {
  return (
    <div className="achieve-section">
      <div className="achieve-container">
        <div className="achieve-text">
          <h5>About Us</h5>
          <h1>Achieve Your Goal of Business</h1>
          <p>
            Use receiving a growing number of currencies and get paid like
            design receiving a growing number of payments.
          </p>
          <div className="achieve-details">
            <h3>Strategic Solutions Pro</h3>
            <p>
              There are many variations of passages of text available. The
              majority have suffered alteration.
            </p>
          </div>
          <div className="achieve-details">
            <h3>Strategic Solutions Pro</h3>
            <p>
              There are many variations of passages of text available. The
              majority have suffered alteration.
            </p>
          </div>
          <button className="read-more-btn">Read More</button>
        </div>
      </div>
    </div>
  );
};

export default Achieve;
