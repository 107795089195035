import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Header from "./components/Header";
import IconsHeader from "./components/IconsHeader";
import Footer from "./components/Footer";
import MainAboutUs from "./pages/MainAboutUs";
import Services_1 from "./components/Services/Services_1";
import Services_2 from "./components/Services/Services_2";
import Services_3 from "./components/Services/Services_3";
import Services_4 from "./components/Services/Services_4";
import Services_5 from "./components/Services/Services_5";
import Services_6 from "./components/Services/Services_6";
import Services_7 from "./components/Services/Services_7";
import Services_8 from "./components/Services/Services_8";
import Services_9 from "./components/Services/Services_9";
import Services_10 from "./components/Services/Services_10";
import { Contact } from "./pages/contact";
import Services_11 from "./components/Services/Services_11";
import Services_12 from "./components/Services/Services_12";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Router>
    
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/main-about-us" element={<MainAboutUs />} />
        <Route path="/services_1" element={<Services_1 />} />
        <Route path="/services_2" element={<Services_2 />} />
        <Route path="/services_3" element={<Services_3 />} />
        <Route path="/services_4" element={<Services_4 />} />
        <Route path="/services_5" element={<Services_5 />} />
        <Route path="/services_6" element={<Services_6 />} />
        <Route path="/services_7" element={<Services_7 />} />
        <Route path="/services_8" element={<Services_8 />} />
        <Route path="/services_9" element={<Services_9 />} />
        <Route path="/services_10" element={<Services_10 />} />
        <Route path="/services_11" element={<Services_11 />} />
        <Route path="/services_12" element={<Services_12 />} />


        <Route path="/contact" element={<Contact />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
