import React from 'react'
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Contact } from "../../pages/contact";

const Services_12 = () => {
  return (
    <div className="services-container"> {/* Reusing services-container */}
      <Container>
        {/* Header Section */}
        <section className="services-header">
          <h1>Web Development Services</h1>
          <p>
            Professional web development services that transform your ideas into reality. From frontend to backend, we build websites that drive results.
          </p>
        </section>

        {/* Services Section */}
        <section className="services-content">
          <h2>Our Services</h2>
          <Row>
            <Col md={4} className="component frontend">
              <h3>Frontend Development</h3>
              <p>
                Build user-friendly and responsive interfaces using the latest technologies like React, Vue, and Angular.
              </p>
            </Col>
            <Col md={4} className="component backend">
              <h3>Backend Development</h3>
              <p>
                Develop powerful backend solutions with Node.js, Python, PHP, and databases like MongoDB and MySQL.
              </p>
            </Col>
            <Col md={4} className="component fullstack">
              <h3>Full-Stack Development</h3>
              <p>
                End-to-end development services, from designing responsive UIs to building robust server-side logic.
              </p>
            </Col>
          </Row>
        </section>

        {/* Features Section */}
        <section className="services-content">
          <h2>Why Choose Us?</h2>
          <ul>
            <li>
              <p><strong>Custom Solutions:</strong> We tailor web applications to meet your unique business needs.</p>
            </li>
            <li>
              <p><strong>Scalable & Secure:</strong> Our web solutions are built to scale with your business, while keeping security at the forefront.</p>
            </li>
            <li>
              <p><strong>Agile Development:</strong> We follow agile methodologies to ensure timely delivery and flexibility.</p>
            </li>
            <li>
              <p><strong>Cross-Browser Compatibility:</strong> We ensure that your website works flawlessly across all major browsers.</p>
            </li>
          </ul>
        </section>

        {/* Call to Action Section */}
        <section className="services-content">
          <h2>Get Your Web Project Started</h2>
          <p>
            Contact us today to discuss your web development needs. Let’s build something amazing together!
          </p>
        </section>
      </Container>
    </div>
  )
}

export default Services_12;