import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Contact } from "../../pages/contact";

const Services_7 = () => {
  return (
    <div className="services-container">
      <Container>
        <section className="services-header">
          <h1>
            Increase Your Business and Sell with our Software Development
            Services
          </h1>
          <p>
            Our company has been at the forefront of technology for over 20
            years, providing exceptional hardware circuit and PCB design
            services. In addition to our expertise in hardware, we have expanded
            our offerings to include comprehensive software services. We
            specialize in mobile and desktop application development as well as
            website creation, ensuring a holistic approach to meet the diverse
            technical needs of our clients. Hence, we are known in India for
            creating the perfect combination of any kind of hardware and
            software.
          </p>
        </section>
        <section className="services-content">
          <ul>
            <li>
              <h2>
                Mobile and Desktop Application Development
                <p>
                  Our company excels in developing robust and intuitive mobile
                  and desktop applications. We understand the growing demand for
                  seamless, user-friendly applications that cater to various
                  platforms. Our team is proficient in creating both
                  multi-platform and native applications, leveraging the latest
                  technologies to deliver high-quality solutions.
                </p>
              </h2>
            </li>
          </ul>
          <h2>Multi-Platform Application Development </h2>
          <ul>
            <p>
              For multi-platform application development, we utilize powerful
              frameworks such as Flutter and technologies like Node.js. Flutter,
              developed by Google, allows us to create natively compiled
              applications for mobile, web, and desktop from a single codebase.
              This ensures a consistent user experience across different
              platforms while reducing development time and costs. Node.js, with
              its event-driven architecture, is perfect for building scalable
              network applications, making it an ideal choice for real-time
              applications like chat apps and online gaming.{" "}
            </p>
          </ul>
          <h2>Native Application Development</h2>
          <ul>
            <p>
              When it comes to native application development, our expertise is
              second to none. We use Xcode for iOS development and Android
              Studio for Android development. These integrated development
              environments (IDEs) provide the necessary tools and frameworks to
              create high-performance native apps that fully leverage the
              capabilities of each platform. Native apps offer superior
              performance and a more tailored user experience, which can be
              crucial for applications requiring intensive graphics, real-time
              interactions, or advanced device functionalities.
            </p>
          </ul>
          <h2>Desktop Application Development </h2>
          <p>
            For desktop application development, we rely on powerful IDEs like
            Visual Studio and languages such as C# and Java. Visual Studio, with
            its comprehensive set of tools, supports the development of complex
            desktop applications for Windows. C# is our language of choice for
            its versatility and efficiency in building desktop applications,
            especially those requiring integration with other Microsoft
            technologies. Java, known for its portability, allows us to create
            cross-platform desktop applications that run seamlessly on various
            operating systems.
          </p>

          <h2>Website Development </h2>
          <p>
            In the realm of website development, our company provides end-to-end
            solutions, from design to deployment. We utilize a range of
            technologies to build responsive, dynamic, and visually appealing
            websites. Our expertise spans across front-end and back-end
            development, ensuring a complete and cohesive web presence for our
            clients.
          </p>

          <h2>Front-End Development </h2>
          <p>
            For front-end development, we use HTML, CSS, and JavaScript to
            create engaging and interactive user interfaces. HTML provides the
            structure of web pages, CSS enhances their appearance with styling,
            and JavaScript brings them to life with dynamic behaviors. Our team
            is adept at using modern JavaScript frameworks and libraries, such
            as React and Angular, to build single-page applications (SPAs) that
            offer a smooth and responsive user experience.
          </p>

          <h2>Back-End Development </h2>
          <p>
            Our back-end development capabilities include using languages and
            frameworks such as PHP, Python, and Node.js. PHP is a powerful
            server-side scripting language that is widely used for web
            development. It is particularly effective for building content
            management systems (CMS) and e-commerce platforms. Python, with its
            simplicity and readability, is ideal for developing complex web
            applications, data analysis tools, and machine learning models.
            Node.js, known for its asynchronous event-driven architecture, is
            perfect for building scalable and high-performance back-end systems.
          </p>
          <h2>Content Management Systems and E-commerce Platforms </h2>
          <p>
            We also specialize in creating and customizing websites using
            popular content management systems (CMS) like WordPress. WordPress
            is a versatile platform that allows us to build anything from simple
            blogs to complex e-commerce sites. Its extensive plugin ecosystem
            enables us to add a wide range of functionalities to meet the
            specific needs of our clients. For e-commerce platforms, we use
            WooCommerce, a powerful WordPress plugin, to create robust and
            scalable online stores.
          </p>

          <h2>Custom Software Solutions</h2>
          <p>
            In addition to standard development services, we provide custom
            software solutions tailored to the unique requirements of our
            clients. We employ a wide array of technologies and programming
            languages to develop bespoke applications that solve specific
            business challenges. Our team works closely with clients to
            understand their needs and deliver solutions that enhance
            efficiency, productivity, and profitability.
          </p>
          <h2>Technology Stack </h2>
          <p>
            Our comprehensive technology stack includes:
            <br />
            • Node.js: For scalable network applications and real-time web
            applications.
            <br />
            • Flutter: For multi-platform application development.
            <br />
            • Python: For web development, data analysis, and machine learning.
            <br />
            • WordPress: For CMS-based website development.
            <br />
            • PHP: For server-side scripting and web development.
            <br />
            • C#: For desktop application development.
            <br />
            • Java: For cross-platform desktop applications and enterprise-level
            applications.
            <br />
            • JavaScript, HTML, CSS: For front-end development and interactive
            user interfaces.
            <br />
            • Xcode and Android Studio: For native mobile application
            development.
            <br />• Visual Studio: For comprehensive desktop application
            development.
          </p>

          <h2>Commitment to Quality and Innovation</h2>
          <p>
            Our commitment to quality and innovation drives us to stay abreast
            of the latest technological advancements. We continuously upgrade
            our skills and tools to ensure that we deliver cutting-edge
            solutions to our clients. Our team of experienced developers,
            designers, and engineers work collaboratively to transform ideas
            into reality, ensuring that our clients achieve their business
            objectives.
            <br />
            In conclusion, our company offers a wide range of software services,
            including mobile and desktop application development, website
            creation, and custom software solutions. With our extensive
            expertise in multi-platform and native app development, and a robust
            technology stack, we are well-equipped to meet the diverse needs of
            our clients. We are dedicated to delivering high-quality,
            innovative, and reliable software solutions that drive success and
            growth for our clients.
          </p>
         
        </section>
        <Contact/>

      </Container>
    </div>
  );
};

export default Services_7;
