import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import {
  FaNetworkWired,
  FaTools,
  FaProjectDiagram,
  FaServicestack,
} from "react-icons/fa";
import "./OurFeatures.css";
import { useNavigate } from "react-router-dom";

const serviceData = [
  {
    icon: <FaNetworkWired size={50} color="#1a6164" />,
    title: "Embedded Systems Design R&D Firmware Development Services",
    text: "Embedded Systems: The Backbone of Modern Technology",
    route: "/Services_1",
  },
  {
    icon: <FaTools size={50} color="#1a6164" />,
    title: "Software Development Services",
    text: "Increase Your Business and Sell with our Software Development Services",
    route: "/Services_2",
  },
  {
    icon: <FaProjectDiagram size={50} color="#1a6164" />,
    title: "Automation and Robotics Services",
    text: "Advancing Your Business with Cutting-Edge Automation and Robotics Services",
    route: "/Services_3",
  },
  {
    icon: <FaServicestack size={50} color="#1a6164" />,
    title: "Hardware schematic and PCB design services",
    text: "Transform your idea, in a circuit board with our Hardware schematic and PCB design services",
    route: "/Services_4",
  },
  {
    icon: <FaNetworkWired size={50} color="#1a6164" />,
    title: "Sales and Custom Manufacturing services",
    text: "Increase your Manufacturing speed with our Electronic Components Sales and Custom Manufacturing services",
    route: "/Services_5",
  },
  {
    icon: <FaTools size={50} color="#1a6164" />,
    title: "Branding With Digital Marketing Services",
    text: "Enhance Your Business with Our Comprehensive Digital Marketing Services",
    route: "/Services_6",
  },
  {
    icon: <FaProjectDiagram size={50} color="#1a6164" />,
    title: "GPS Tracking and IoT Services",
    text: "Comprehensive Solutions in GPS Tracking and IoT: Your Trusted Partner",
    route: "/Services_7",
  },
  {
    icon: <FaServicestack size={50} color="#1a6164" />,
    title: "Comprehensive Door Lock Systems Services",
    text: "Advanced Security Solutions: Comprehensive Door Lock Systems",
    route: "/Services_8",
  },
  {
    icon: <FaNetworkWired size={50} color="#1a6164" />,
    title: "3D Modelling and Printing Services",
    text: "Revolutionize Your Projects with Our Expert 3D Modelling and Printing Services",
    route: "/Services_9",
  },
  {
    icon: <FaTools size={50} color="#1a6164" />,
    title: "Plastic and Wood Laser Cutting Services",
    text: "Transform Your Projects with Our Precision Plastic and Wood Laser Cutting Services",
    route: "/Services_10",
  },
  {
    icon: <FaTools size={50} color="#1a6164" />,
    title: "Digital Marketing",
    text: "Transform Your Projects with Our Precision Plastic and Wood Laser Cutting Services",
    route: "/Services_11",
  },
  {
    icon: <FaTools size={50} color="#1a6164" />,
    title: "Web Developer             ",
    text: "Transform Your Projects with Our Precision Plastic and Wood Laser Cutting Services",
    route: "/Services_12",
  },
];

const OurFeatures = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = (route) => {
    navigate(route);
  };

  return (
    <div className="our-features-container">
      <Container>
        <Row>
          <Col md={6} sm={12}>
            <div className="features-heading">
              <h5>Our Features</h5>
              <h1>Partnering Business for Success</h1>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="features-content">
              <p>
              Techsodes Pvt. Ltd. is actively seeking strategic partnerships to enhance our market presence, expand our product offerings, and drive sustainable growth. 
              </p>
            </div>
          </Col>
        </Row>
        <Row className="feature-item">
            <Col md={4} sm={12}>
              <h1>Business Growth Catalyst</h1>
            </Col>
            <Col md={4} sm={12}>
              <p>
              Techsodes Pvt. Ltd. is focused on growth. We seek partnerships, innovate, and strive for operational excellence.
              </p>
            </Col>
            <Col md={4} sm={12}>
              <Button>
                Read more<span></span>
              </Button>
            </Col>
          </Row>
          <Row  className="feature-item">
            <Col md={4} sm={12}>
              <h1>Innovative Digital Transformation</h1>
            </Col>
            <Col md={4} sm={12}>
              <p>
              We drive innovation by leveraging the latest technologies to help your business transition into the digital era, enhancing efficiency, agility, and customer engagement.              </p>
            </Col>
            <Col md={4} sm={12}>
              <Button>
                Read more<span></span>
              </Button>
            </Col>
          </Row><Row  className="feature-item">
          <Col md={4} sm={12}>
            <h1>Strategic Market Expansion</h1>
          </Col>
          <Col md={4} sm={12}>
            <p>
            Our data-driven strategies open new avenues for market expansion, ensuring your business not only reaches but thrives in untapped markets with targeted campaigns.
            </p>
          </Col>
          <Col md={4} sm={12}>
            <Button>
              Read more<span></span>
            </Button>
          </Col>
        </Row>
        <Row  className="feature-item">
          <Col md={4} sm={12}>
            <h1>Long-Term Success Partnership</h1>
          </Col>
          <Col md={4} sm={12}>
            <p>
            Our collaboration goes beyond short-term gains. We aim to build a sustainable, long-term partnership by continuously refining strategies and offering ongoing support for your evolving business needs.            </p>
          </Col>
          <Col md={4} sm={12}>
            <Button>
              Read more<span></span>
            </Button>
          </Col>
        </Row>
      </Container>

      <Container>
        <div className="latestservice" >
          <h5>Latest Service</h5>
          <h1>Provide Quality Services</h1>
          <div className="cards-section">
            <Row>
              {serviceData.slice(0, 12).map((service, index) => (
                <Col md={3} sm={6} key={index} className="service-col">
                  <Card className="service-card">
                    <div className="card-front">
                      {service.icon}
                      <Card.Title>{service.title}</Card.Title>
                    </div>
                    <div className="card-back">
                      <Card.Text>{service.text}</Card.Text>
                      <Button
                        variant="primary"
                        onClick={() => handleLearnMoreClick(service.route)}
                      >
                        READ MORE
                      </Button>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row> 
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OurFeatures;
