import React from 'react'
import './contact.css'; // Import the CSS file
import { Container } from 'react-bootstrap';
import AboutBImg from "../img/breadcrumb-thumb.png";

export const Contact = () => {
  return (
    <>
    <div className=" "></div>
    <section>
      <div className="containerr">
        <div className="cs_contact_ms">
          <div className="cs_contact">
          <div className='about-banner'>
          <Container>
            <div className='flex-container'>
              <div className='text-container'>
                <h1 className='heading'>Contact Us</h1>
              </div>
              <div className='image-container'>
                <img className='image' src={AboutBImg} alt='About Us' />
              </div>
            </div>
          </Container>
        </div>
            <div className="contact__container">
  <div className="contact__text">
   
    <h1 className="contact__title animation--text-write">Get in Touch</h1>
  </div>
  
  <div className="contact__form-container animation--form-slide">
    <form className="contact__form">
      <div className="form__row">
        <div className="form__field">
          <input
            className="form__input"
            type="text"
            id="name"
            placeholder="Name"
            name="name"
          />
          <label htmlFor="name" className="form__label">Name</label>
        </div>
        <div className="form__field">
          <input
            className="form__input"
            type="email"
            id="email"
            placeholder="Email"
            name="email"
          />
          <label htmlFor="email" className="form__label">Email</label>
        </div>
      </div>

      <div className="form__row">
        <div className="form__field">
          <input
            className="form__input"
            type="number"
            id="phone"
            placeholder="Phone Number"
            name="phone"
          />
          <label htmlFor="phone" className="form__label">Phone</label>
        </div>
        <div className="form__field">
          <input
            className="form__input"
            type="text"
            id="subject"
            placeholder="Subject"
            name="subject"
          />
          <label htmlFor="subject" className="form__label">Subject</label>
        </div>
      </div>

      <div className="form__field">
        <textarea
          className="form__input"
          id="message"
          placeholder="Message"
          name="message"
        />
        <label htmlFor="message" className="form__label">Message</label>
      </div>

      <button type="submit" className="button button--submit">
        <span>Send Now</span>
        <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.5303 7.03033C18.8232 6.73744 18.8232 6.26256 18.5303 5.96967L13.7574 1.1967C13.4645 0.903806 12.9896 0.903806 12.6967 1.1967C12.4038 1.48959 12.4038 1.96447 12.6967 2.25736L16.9393 6.5L12.6967 10.7426C12.4038 11.0355 12.4038 11.5104 12.6967 11.8033C12.9896 12.0962 13.4645 12.0962 13.7574 11.8033L18.5303 7.03033ZM0 7.25H18V5.75H0V7.25Z"
            fill="currentColor"
          ></path>
        </svg>
      </button>
    </form>
  </div>

  <div className="contact__info animation--side-slide">
  <div className="contact__map">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3562.468818681117!2d75.77331111504192!3d26.862087479909926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5b194fa392b%3A0x536a5390e6f76404!2sShipra%20Path%2C%20Mansarovar%2C%20Jaipur%2C%20Rajasthan%20302020!5e0!3m2!1sen!2sin!4v1695018310047!5m2!1sen!2sin"
    width="900"
    height="450"
    allowFullScreen=""
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
</div>

    
    <div className="contact__details">
      <div className="detail">
        <i className="detail__icon icon--address"></i>
        <h6 className="detail__title">Address</h6>
        <p className="detail__content">Techsodes Corporation Pvt. Ltd.
        Office No. 2, L-Barcelona <br/>
        Puranik City<br/>
        Opposite Tieten Medicity Hospital<br/>
        Kasarvadavali, G. B. Road<br/>
        Thane - 400615<br/>
        Mumbai,  Maharashtra</p>
      </div>
      <div className="detail">
        <i className="detail__icon icon--phone"></i>
        <h6 className="detail__title">Phone</h6>
        <p className="detail__content">+91 99300 12356 <br/>
        +91 89555 12356</p>
      </div>
      <div className="detail">
        <i className="detail__icon icon--email"></i>
        <h6 className="detail__title">Email</h6>
        <p className="detail__content">info@techsodes.com <br/>
        techsodes@gmail.com</p>
      </div>
    </div>
  </div>
</div>

        </div>
      </div>
      </div>
    </section>
    <div className="cs_height_150 cs_height_lg_120"></div>
  </> )
}
