import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Process_1 from '../../img/process-icon-1-1.svg';
import './WorkProcess.css'; // Import the CSS file

const WorkProcess = () => {
  return (
    <div className="work-process-section">
      <h5>Work Process</h5>
      <h1>Unleash Business's Hidden Potential</h1>
      <Container>
        <Row>
          <Col md={3} sm={6} className="mb-4">
            <Card className="process-card">
              <Card.Img variant="top" src={Process_1} alt="Process Icon" />
              <Card.Body>
                <Card.Title>Technical Operation</Card.Title>
                <Card.Text>Growth Acceler</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <Card className="process-card">
              <Card.Img variant="top" src={Process_1} alt="Process Icon" />
              <Card.Body>
                <Card.Title>Technical Operation</Card.Title>
                <Card.Text>Growth Acceler</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <Card className="process-card">
              <Card.Img variant="top" src={Process_1} alt="Process Icon" />
              <Card.Body>
                <Card.Title>Technical Operation</Card.Title>
                <Card.Text>Growth Acceler</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <Card className="process-card">
              <Card.Img variant="top" src={Process_1} alt="Process Icon" />
              <Card.Body>
                <Card.Title>Technical Operation</Card.Title>
                <Card.Text>Growth Acceler</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WorkProcess;
