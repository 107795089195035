
import React from 'react'
import Hero from '../components/Hero'
import AboutUs from './AboutUs'
import OurFeatures from './OurFeatures'
import LatestPortfolio from './LatestPortfolio'
import OurTeam from './OurTeam'
import AskQuestion from './AskQuestion'
import UpcomingEvents from './UpcomingEvents'
import LatestBlog from './LatestBlog'
import { Contact } from './contact'
const Home = () => {
  return (
    <>
    <Hero/>
    <AboutUs/>
    <OurFeatures/>
    <LatestPortfolio/>
    <OurTeam/>
    <AskQuestion/>
    <UpcomingEvents/>
    <LatestBlog/>
    <Contact/>
    </>
  )
}

export default Home