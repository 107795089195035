import React from 'react'
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Contact } from "../../pages/contact";

const Services_11 = () => {
  return  (
    <div className="services-container"> {/* Reusing same container */}
      <Container>
        {/* Header Section */}
        <section className="services-header">
          <h1>Digital Marketing Services</h1>
          <p>
            Elevate your business with our comprehensive digital marketing solutions. From SEO to social media, we deliver measurable results.
          </p>
        </section>

        {/* Services Section */}
        <section className="services-content">
          <h2>Our Services</h2>
          <Row>
            <Col md={4} className="component seo">
              <h3>Search Engine Optimization (SEO)</h3>
              <p>
                Boost your website’s visibility on search engines and drive organic traffic with our tailored SEO strategies.
              </p>
            </Col>
            <Col md={4} className="component ppc">
              <h3>Pay-Per-Click (PPC) Advertising</h3>
              <p>
                Target the right audience with cost-effective PPC campaigns on Google, Facebook, and more to maximize ROI.
              </p>
            </Col>
            <Col md={4} className="component social-media">
              <h3>Social Media Marketing</h3>
              <p>
                Engage with your audience and build brand loyalty through compelling social media campaigns on platforms like Instagram, Facebook, and LinkedIn.
              </p>
            </Col>
          </Row>
        </section>

        {/* Features Section */}
        <section className="services-content">
          <h2>Why Choose Us?</h2>
          <ul>
            <li>
              <p><strong>Data-Driven Approach:</strong> We analyze data to optimize performance and make informed decisions.</p>
            </li>
            <li>
              <p><strong>Experienced Team:</strong> Our team of digital marketing experts brings years of experience to the table.</p>
            </li>
            <li>
              <p><strong>Proven Results:</strong> We have helped clients across various industries achieve measurable growth.</p>
            </li>
            <li>
              <p><strong>Customized Solutions:</strong> We create marketing strategies that are tailored to your specific business needs.</p>
            </li>
          </ul>
        </section>

        {/* Call to Action Section */}
        <section className="services-content">
          <h2>Ready to Take Your Business to the Next Level?</h2>
          <p>
            Contact us today to learn more about our digital marketing services and get a free consultation.
          </p>
        </section>
      </Container>
    </div>
  )
}

export default Services_11;