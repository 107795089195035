import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import TestTumb_1 from "../../img/testi-thumb_1.png";
import TestTumb_2 from "../../img/testi-thumb_2.png";
import "./ClientsTestimonial.css"; // Import the CSS file
import { FaStar } from "react-icons/fa";

const ClientsTestimonial = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Remove arrow buttons
  };

  const testimonials = [
    {
      img: TestTumb_1,
      text: "Dictum ultrices porttitor amet nec sollicitudin mi molestie adipiscing netus. Lorem at ac ut morbi ullamcorper molestie lacus loren ipsum dummy text provide happy Dictum ultrices porttitor amet nec sollicitudin mi molestie adipiscing netus. Lorem at ac ut morbi ullamcorper molestie lacus loren ipsum dummy text provide happy",
      name: "John Doe",
      position: "CEO, ABC Company",
    },
    {
      img: TestTumb_2,
      text: "Dictum ultrices porttitor amet nec sollicitudin mi molestie adipiscing netus. Lorem at ac ut morbi ullamcorper molestie lacus loren ipsum dummy text provide happyDictum ultrices porttitor amet nec sollicitudin mi molestie adipiscing netus. Lorem at ac ut morbi ullamcorper molestie lacus loren ipsum dummy text provide happy",
      name: "Jane Smith",
      position: "CTO, XYZ Company",
    },
    // Add more testimonials here if needed
  ];

  return (
    <div className="clients-testimonial-section">
      <Row className="mb-5">
        <Col md={12} className="heading-container">
          <div>
            <h5>Testimonial</h5>
            <h1>Your Business Goals with Confidence</h1>
          </div>
          <p style={{ width: "50%", fontSize: "18px" }}>
            Dictum ultrices porttitor amet nec sollicitudin mi molestie
            adipiscing netus. Lorem at ac ut morbi ullamcorper molestie lacus
            loren ipsum dummy text provide happy
          </p>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={4} className="fixed-image-col">
          <div className="testimonial-img-container"></div>
        </Col>
        <Col md={8} className="testimonial-slider-col">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-slide">
                <div style={{ marginBottom: "20px" }}>
                  <FaStar size={30} color="#b8bc08" />
                  <FaStar size={30} color="#b8bc08" />
                  <FaStar size={30} color="#b8bc08" />
                  <FaStar size={30} color="#b8bc08" />
                  <FaStar size={30} color="#fff" />
                </div>
                <p className="testimonial-text">{testimonial.text}</p>
                <div className="testimonial-content">
                  <img
                    src={testimonial.img}
                    alt={testimonial.name}
                    className="testimonial-thumb"
                  />
                  <div className="testimonial-author">
                    <h5>{testimonial.name}</h5>
                    <p>{testimonial.position}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Col>
      </Row>
      <div className="counter-section">
        <Container>
          <Row>
            <Col md={3} className="counter">
              <h2>100+</h2>
              <p>Happy Clients</p>
            </Col>
            <Col md={3} className="counter">
              <h2>200+</h2>
              <p>Projects Completed</p>
            </Col>
            <Col md={3} className="counter">
              <h2>50+</h2>
              <p>Awards Won</p>
            </Col>
            <Col md={3} className="counter">
              <h2>10+</h2>
              <p>Years of Experience</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ClientsTestimonial;
