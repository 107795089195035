import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Contact } from "../../pages/contact";

const Services_6 = () => {
  return (
    <div className="services-container">
      <Container>
        <section className="services-header">
          <h1>
            Transform your idea, in a circuit board with our Hardware schematic
            and PCB design services
          </h1>
          <p>
            Our company has been a leader in hardware circuit and PCB design for
            over 20 years, providing top-notch services in analog, digital, and
            microcontroller circuit and PCB design. Over these two decades, we
            have accumulated extensive experience and a deep understanding of
            the intricacies involved in creating efficient, reliable, and
            high-performance designs. Our expertise is supported by advanced
            tools such as Altium Designer and Proteus, which we use to ensure
            precision and excellence in our work.
          </p>
        </section>
        <section className="services-content">
          <ul>
            <li>
              <h2>
                Analog Circuit Design
                <p>
                  Analog circuit design is one of our core strengths. We have
                  designed a wide range of analog circuits for various
                  applications, ensuring that each design meets the specific
                  requirements of our clients. From simple amplifiers to complex
                  signal processing circuits, our team has the knowledge and
                  experience to deliver high-quality analog solutions. We
                  understand the critical nature of analog design, where even
                  the slightest variation can affect the performance of the
                  entire system. Therefore, we pay meticulous attention to every
                  detail, ensuring that our designs are robust and reliable.
                </p>
              </h2>
            </li>
          </ul>
          <h2>Digital Circuit Design </h2>
          <ul>
            <p>
              Digital circuit design is another area where we excel. Our team
              has developed numerous digital circuits, ranging from basic logic
              gates to complex microprocessor-based systems. We use the latest
              design methodologies and tools to create digital circuits that are
              efficient, scalable, and easy to integrate into larger systems.
              Our expertise in digital design allows us to handle a wide variety
              of projects, from simple controllers to sophisticated computing
              systems.
            </p>
          </ul>
          <h2>Microcontroller Circuit Design </h2>
          <ul>
            <p>
              Microcontroller circuit design is an integral part of our
              services. We have designed numerous microcontroller-based circuits
              for various applications, including industrial automation,
              consumer electronics, and medical devices. Our team has experience
              with a wide range of microcontrollers from different
              manufacturers, enabling us to select the best solution for each
              project. We ensure that our microcontroller designs are optimized
              for performance, power consumption, and cost, providing our
              clients with the best possible outcomes.
            </p>
          </ul>
          <h2>SMPS Battery Chargers</h2>
          <p>
            One of our specialized areas is the design of SMPS (Switch Mode
            Power Supply) battery chargers. We have worked on numerous projects
            involving the design and development of efficient and reliable SMPS
            battery chargers. These chargers are essential for various
            applications, including renewable energy systems, electric vehicles,
            and portable electronic devices. Our designs ensure optimal charging
            performance, safety, and longevity of the batteries, addressing the
            specific needs of each application. We incorporate advanced features
            such as temperature compensation, overcharge protection, and
            efficient power conversion to deliver top-quality battery chargers.
          </p>

          <h2>Motor Drivers </h2>
          <p>
            Our expertise extends to the design of motor drivers as well. We
            have developed motor drivers for various types of motors, including
            DC motors, stepper motors, and brushless DC motors. Our designs are
            used in applications such as robotics, industrial automation, and
            consumer electronics. We ensure that our motor drivers are
            efficient, reliable, and capable of handling the specific
            requirements of each application. Our designs include advanced
            features such as speed control, torque control, and fault protection
            to ensure optimal performance and safety.
          </p>

          <h2>Software Tools: Altium Designer and Proteus</h2>
          <p>
            To achieve the highest standards of design and reliability, we rely
            on state-of-the-art software tools like Altium Designer and Proteus.
            Altium Designer allows us to create detailed and precise PCB
            layouts, ensuring that our designs are manufacturable and meet
            industry standards. It provides a comprehensive set of tools for
            schematic capture, PCB layout, and design validation, enabling us to
            streamline our design process and reduce time-to-market.
            <br />
            Proteus, on the other hand, is an essential tool for simulating and
            validating our circuit designs. It allows us to test our designs in
            a virtual environment, identifying and resolving issues before
            moving to the manufacturing stage. This reduces the risk of errors
            and ensures that our designs work as intended when they are built.
          </p>

          <h2>Client-Centric Approach</h2>
          <p>
            Our client-centric approach is at the heart of everything we do. We
            work closely with our clients to understand their specific needs and
            requirements, ensuring that our designs meet their expectations. Our
            team is committed to providing exceptional service, from the initial
            consultation and design phase to prototyping, testing, and final
            delivery. We believe in building long-term relationships with our
            clients, based on trust, transparency, and mutual respect.
          </p>
          <h2>Innovation and Quality</h2>
          <p>
            Innovation and quality are the cornerstones of our company. We
            continuously strive to improve our designs and processes, staying
            abreast of the latest advancements in technology. Our commitment to
            quality is reflected in every aspect of our work, from the initial
            concept to the final product. We use the best materials and
            components, adhere to industry standards, and follow rigorous
            testing procedures to ensure that our designs are of the highest
            quality.
            <br />
            In conclusion, our company has established itself as a trusted
            provider of hardware circuit and PCB design services, with a proven
            track record of excellence over the past 20 years. Our expertise in
            analog, digital, and microcontroller circuit design, combined with
            our experience in SMPS battery chargers and motor drivers, enables
            us to deliver high-quality, reliable, and innovative solutions to
            our clients. We are dedicated to meeting the needs of our clients
            and exceeding their expectations, ensuring that they receive the
            best possible service and support.
          </p>
        </section>
        <Contact/>

      </Container>
    </div>
  );
};

export default Services_6;
