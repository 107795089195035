import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Contact } from "../../pages/contact";

const Services_3 = () => {
  return (
    <div className="services-container">
      <Container>
        <section className="services-header">
          <h1>
            Revolutionize Your Projects with Our Expert 3D Modelling and
            Printing Services
          </h1>
          <p>
            In the rapidly advancing world of technology and design, 3D
            modelling and printing have become essential tools across a range of
            industries. Our company specializes in delivering premium 3D
            modelling and printing services, catering to diverse needs from
            concept design to final production. Whether you're an architect,
            engineer, product designer, or hobbyist, we provide the expertise
            and state-of-the-art technology necessary to bring your ideas to
            life. Our comprehensive services cover every aspect of 3D design and
            printing, ensuring high-quality results tailored to your specific
            requirements.
          </p>
        </section>
        <section className="services-content">
          <h2>
            Give your ideas a 3D shape with our 3D modeling and printing
            services
          </h2>
          <ul>
            <li>
              <p>
                Our 3D modelling services are designed to turn your ideas into
                detailed digital representations. We utilize advanced software
                and a team of skilled designers to create precise and highly
                detailed 3D models. Our services include:
              </p>
            </li>
          </ul>
          <h2>Conceptual Design: </h2>
          <ul>
            <p>
              We collaborate closely with clients to understand their vision and
              requirements, translating them into initial 3D models. This phase
              involves brainstorming, sketching, and creating rough drafts to
              capture the core of your concept.
            </p>
          </ul>
          <h2>Detailed Modelling: </h2>
          <ul>
            <p>
              After finalizing the concept, our designers develop detailed 3D
              models using industry-leading software such as AutoCAD,
              SolidWorks, and Blender. We focus on precision and intricacy,
              ensuring every aspect of your design is accurately represented.
            </p>
          </ul>
          <h2>Prototyping: </h2>
          <p>
            Our 3D modelling services are ideal for creating prototypes. We
            develop models that allow you to visualize and test your designs
            before proceeding to production. This process helps identify
            potential issues and make necessary adjustments early on.
          </p>
          <h2>Product Design and Development: </h2>
          <p>
            From consumer products to industrial equipment, our 3D modelling
            services support the entire product development lifecycle. We create
            models that facilitate design validation, testing, and iteration,
            ensuring your product is market-ready.
          </p>

          <h2>Architectural Visualization: </h2>
          <p>
            For architects and builders, we offer 3D modelling services that
            bring blueprints and plans to life. Our models provide a realistic
            visualization of buildings and spaces, aiding in project planning,
            marketing, and client presentations.
          </p>

          <h2>3D Printing Services:</h2>
          <p>
            Our 3D printing services complement our modelling capabilities,
            providing a seamless transition from digital design to physical
            object. We utilize cutting-edge 3D printing technology to produce
            high-quality prints with precision and efficiency. Our services
            include:
          </p>
          <h2>Rapid Prototyping: </h2>
          <p>
            Speed is crucial in today’s competitive market. Our rapid
            prototyping services enable you to quickly produce physical models
            from your 3D designs. This process accelerates product development,
            allowing for faster testing and iteration.
          </p>
          <h2>Custom Manufacturing: </h2>
          <p>
            Whether you need a single part or a small batch of products, our 3D
            printing services offer flexibility and customization. We can
            produce intricate designs and complex geometries that are difficult
            or impossible to achieve with traditional manufacturing methods.
          </p>
          <h2>Material Variety: </h2>
          <p>
            We offer a wide range of materials to suit different applications,
            including plastics, resins, metals, and composites. Our experts help
            you choose the best material for your project, ensuring optimal
            performance and durability.
          </p>

          <h2>End-Use Production: </h2>
          <p>
            3D printing is not limited to prototyping. We provide end-use
            production services, creating final parts and products that meet
            industry standards. Our prints are suitable for various
            applications, from functional components to aesthetic pieces.
          </p>
          <h2>Quality Assurance: </h2>
          <p>
            Quality is our top priority. We implement rigorous quality control
            processes to ensure that every print meets the highest standards.
            Our team inspects each part for accuracy, strength, and finish,
            guaranteeing satisfaction with the final product.
          </p>
          <h2>Applications across Industries: </h2>
          <p>
            Our 3D modelling and printing services cater to a broad spectrum of
            industries, including:
          </p>
          <h2>Aerospace and Automotive: </h2>
          <p>
            We produce lightweight and durable parts that meet the stringent
            requirements of the aerospace and automotive sectors. Our services
            support everything from prototyping to production, enhancing
            innovation and efficiency.
          </p>
          <h2>Healthcare: </h2>
          <p>
            In the medical field, our 3D printing services enable the creation
            of custom implants, prosthetics, and anatomical models. These
            advancements improve patient outcomes and support medical research
            and education.
          </p>
          <h2>Consumer Goods: </h2>
          <p>
            For consumer products, we provide rapid prototyping and small-scale
            production, allowing for quick market entry and customization. Our
            services help businesses stay agile and responsive to market
            demands.
          </p>
          <h2>Education and Research: </h2>
          <p>
            We support educational institutions and research organizations by
            providing 3D models and prints that facilitate learning and
            experimentation. Our services enhance the understanding of complex
            concepts and drive innovation.
          </p>
          <h2>Art and Entertainment: </h2>
          <p>
            Artists and designers use our 3D printing services to create unique
            pieces, props, and models. Our technology brings creative visions to
            life, offering new possibilities in art and entertainment.
          </p>
          <p>
            Partner with us to leverage the power of 3D modelling and printing.
            Our expertise, advanced technology, and commitment to quality ensure
            that your projects are executed flawlessly. Let us help you turn
            your ideas into reality, driving innovation and success in your
            field.
          </p>
        </section>
        <Contact/>

      </Container>
    </div>
  );
};

export default Services_3;
