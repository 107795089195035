import React from 'react';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import './Footer.css';
import logo from "../img/Logo__6_-removebg-preview-removebg-preview.png"
const Footer = () => {
  return (
    <Container fluid className="main-footer">
      <Row className="footer">
        <Col md={3} className="footer-col">
        <img src={logo} style={{width:"60%"}}/>
          <p>It is a long established fact that a reader will be distracted</p>
          <div className="social-icons">
            <a href="https://www.facebook.com/techsodes" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            <a href="https://www.twitter.com/techsodes" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://www.instagram.com/techsodes" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://www.linkedin.com/techsodes" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
          </div>
        </Col>
        <Col md={3} className="footer-col">
          <h5>Services</h5>
          <ul>
            <li> Firmware Development Services</li>
            <li>Software Development Services</li>
            <li>Automation and Robotics Services  </li>
            <li> PCB design services</li>
            <li>Sales and Custom Manufacturing services</li>
            <li>Branding With Digital Marketing Services</li>
            <li>GPS Tracking and IoT Services</li>
            <li> Door Lock Systems Services</li>
            <li>3D Modelling and Printing Services</li>
            <li>Digital Marketing</li>
            <li>Web Developer</li>


          </ul>
        </Col>
        <Col md={3} className="footer-col">
          <h5>Our Office</h5>
          <p><FaEnvelope /> info@techsodes.com <br/><span><FaEnvelope />techsodes@gmail.com</span></p>
          <p><FaMapMarkerAlt /> Techsodes Corporation Pvt. Ltd.
          Office No. 2, L-Barcelona
          Puranik City
          Opposite Tieten Medicity Hospital
          Kasarvadavali, G. B. Road
          Thane - 400615
          Mumbai,  Maharashtra</p>
          <p><FaPhoneAlt /> +91 9930012356
          +91 8955512356</p>
        </Col>
        <Col md={3} className="footer-col">
          <h5>Our Newsletter</h5>
          <p>Custom Software Development Tailored Solutions for Your</p>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Control type="email" placeholder="Email Address" />
            </Form.Group>
            <Button variant="primary" type="submit">Subscribe</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
