import React from 'react';
import Slider from 'react-slick';
import { Container, Card } from 'react-bootstrap';
import { FaShareAlt } from 'react-icons/fa';  // Share icon from react-icons
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './OurTeam.css';
import Profile_1 from '../img/team-1-1.png';
import Profile_2 from '../img/team-1-2.png';
import Profile_3 from '../img/team-1-3.png';

// Sample data for the team cards with actual images
const teamMembers = [
  {
    name: 'John Doe',
    position: 'CEO',
    image: Profile_1,
  },
  {
    name: 'Jane Smith',
    position: 'CTO',
    image: Profile_2,
  },
  {
    name: 'Emily Johnson',
    position: 'Designer',
    image: Profile_3,
  },
  // Add more team members here if needed
];

const OurTeam = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false, // Remove arrows
    dots: true, // Add dots
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: i => (
      <div className="custom-dot"></div>
    ),
  };

  return (
    <div className="our-team">
      <Container className='OurTeam-section'>
        <Slider {...settings}>
          {teamMembers.map((member, idx) => (
            <div key={idx} className='slider-item'>
              <div className='team-card'>
                <Card.Img variant='top' src={member.image} />
                <div className='card-content'>
                  <div className='card-info'>
                    <Card.Title>{member.name}</Card.Title>
                    <Card.Subtitle className='mb-2 text-muted'>{member.position}</Card.Subtitle>
                  </div>
                  <div className='card-share'>
                    <FaShareAlt className='share-icon' />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default OurTeam;
