import React from 'react';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import LeftImage from '../img/about_3-1.jpg';
import './AboutUs.css'; // Import CSS file

const AboutUs = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLearnMoreClick = () => {
    navigate('/main-about-us'); // Replace '/main-about-us' with your desired route
  };

  return (
    <Container className="about-us-container">
      <Row>
        <Col xs={12} md={6}>
          <div className="left-image">
            <img src={LeftImage} alt="Left" />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="right-text">
            <h5>About Us</h5>
            <h1>Maximizing Through the Strategic</h1>
            <p>
            At Techsodes, we believe that success is driven by strategy. By leveraging data-driven insights, industry expertise, and innovative technologies, we empower businesses to optimize their operations and achieve maximum efficiency.          </p>
            <div className="stats">
              <div className="stat-item">
                <span className="stat-label">Last mile delivery</span>
                <ProgressBar now={90} label="90%" variant="success" />
              </div>
              <div className="stat-item">
                <span className="stat-label">Last mile delivery</span>
                <ProgressBar now={80} label="80%" variant="info" />
              </div>
              <div className="stat-item">
                <span className="stat-label">Finished Work</span>
                <ProgressBar now={95} label="95%" variant="warning" />
              </div>
            </div>
            <button className="learn-more-btn" onClick={handleLearnMoreClick}>
              Learn More
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
