import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AskQuestion.css';

const AskQuestion = () => {
  return (
    <Container className="container-custom">
      <div className="heading-container">
        <h5>Ask Question</h5>
        <h1>Transforming Vision into Reality Together</h1>
      </div>
      <Accordion defaultActiveKey="0" className="accordion-custom">
        <Accordion.Item eventKey="0">
          <Accordion.Header>What Services do you offer? </Accordion.Header>
          <Accordion.Body>
  We offer a wide range of services to help businesses grow and succeed. Our expertise includes web development, digital marketing, and graphic design. Whether you need a new website or want to boost your online presence, we have the skills and experience to deliver exceptional results.
</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className='header'>How long dose it take for you to complate a project?</Accordion.Header>
          <Accordion.Body>
          The duration of a project depends on various factors, including its complexity, scope, and the specific requirements of our clients. We'll provide you with a detailed project timeline once we've had a chance to discuss your needs in more detail.
        </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className='header'>How much dose  it cost  to  work your agency</Accordion.Header>
          <Accordion.Body>
          Our pricing is tailored to each individual project. We believe in providing our clients with the best possible value for their investment. To get an accurate quote, please provide us with information about your project goals and requirements.
        </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default AskQuestion;
