import React from "react";
import "./Hero.css";
import Profile from "../img/hero_thumb_3_1.png";
import Background from "../img/hero_bg_3_1.png";
import collI from  "../img/call.png"
import Pockit from "../img/pockit.png"

const Hero = () => {
  return (
    <div className="hero-container" style={{ backgroundImage: `url(${Background})` }}>
      <div className="hero-content">
        <div className="hero-text">
          <h1>Welcome to</h1>
          <p className="title-2">
            <span className="title-2-b">Techsodes Corporations-</span> 
            <h5>Techsodes meaning Technical Sound Of Developing Engineering Solutions</h5>
          
          </p>
          <p className="desc">
          Your Premier Embedded Systems, Technology, and Software Solutions Partner
          </p>
          <div className="grow-main">
            <div className="grow-1">
              <div>
                <img src={Pockit} alt="" />
              </div>
              <div>
                <p>Need help?</p>
                <p> +91-9930012356</p>
              </div>
            </div>
            <div className="grow-2">
              <div>
                <img src={collI} alt="" />
              </div>
              <div>
                <p>Need help?</p>
                <p>+91 89555 12356</p>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-image">
          <img src={Profile} alt="Hero" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
