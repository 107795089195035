import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Contact } from "../../pages/contact";

const Services_2 = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <div className="services-container">
      <Container>
        <section className="services-header">
          <h1>
            Enhance Your Business with Our Comprehensive Digital Marketing
            Services
          </h1>
          <p>
            In today’s fast-paced digital landscape, a robust online presence is
            crucial for any business aiming to thrive. Our company specializes
            in offering premium digital marketing services that help you reach
            your target audience, increase brand visibility, and drive
            conversions. Our expertise covers various facets of digital
            marketing, including Search Engine Optimization (SEO), Pay-Per-Click
            (PPC) advertising, Google Product Listings, YouTube Ads, and social
            media management for platforms like Instagram, Facebook, Threads,
            and Google Ads. Let us assist you in elevating your business to new
            heights with our comprehensive solutions.
          </p>
        </section>
        <section className="services-content">
          <h2>Search Engine Optimization (SEO):</h2>
          <ul>
            <li>
              <p>
                SEO is the foundation of a successful digital marketing
                strategy. Our team of SEO experts works diligently to enhance
                your website’s visibility on search engines like Google. We
                perform thorough keyword research to identify the most relevant
                and high-traffic keywords for your industry. Our on-page SEO
                services optimize your website’s content, including meta tags,
                headings, and images. Additionally, our off-page SEO efforts,
                such as backlink building and social signals, further boost your
                site’s authority and ranking. By improving your organic search
                rankings, we help you attract more qualified traffic and
                potential customers.
              </p>
            </li>
          </ul>
          <h2>Pay-Per-Click (PPC) Advertising:</h2>
          <ul>
            <p>
              PPC advertising is an effective tool for driving immediate traffic
              and leads to your website. Our PPC specialists design targeted ad
              campaigns on platforms like Google Ads and Bing Ads to reach your
              desired audience. We manage every aspect of your PPC campaigns,
              from keyword selection and ad copy creation to bidding strategies
              and performance monitoring. Our aim is to maximize your return on
              investment (ROI) by ensuring your ads reach the right people at
              the right time. Whether you want to boost sales, generate leads,
              or increase brand awareness, our PPC services deliver measurable
              results.
            </p>
          </ul>
          <h2>Google Product Listings:</h2>
          <ul>
            <p>
              For e-commerce businesses, visibility on Google Shopping can
              significantly impact sales. We optimize your Google Product
              Listings to ensure your products appear prominently in search
              results. Our team handles everything from product data feed
              optimization to creating compelling product descriptions and
              images. By enhancing your product listings, we help you attract
              more potential buyers and increase your online sales.
            </p>
          </ul>
          <h2>YouTube Ads:</h2>
          <p>
            YouTube Ads provide an excellent platform for reaching a vast
            audience through engaging video content. Our YouTube advertising
            services include creating compelling video ads tailored to your
            target audience. We manage ad placement to ensure your videos reach
            users who are most likely to be interested in your products or
            services. By leveraging YouTube's vast user base and sophisticated
            targeting options, we help you increase brand awareness and drive
            traffic to your website. Whether it's skippable ads, non-skippable
            ads, bumper ads, or discovery ads, we tailor our strategy to
            maximize your ROI and engagement.
          </p>
          <h2>Social Media Management:</h2>
          <p>
            In the era of social media, having a strong presence on platforms
            like Instagram, Facebook, and Threads is essential for engaging with
            your audience. Our social media experts create and manage your
            social media profiles, developing content strategies tailored to
            your brand’s voice and goals. We create engaging posts, stories, and
            reels that resonate with your audience, fostering a sense of
            community and loyalty. Through targeted social media advertising, we
            amplify your reach and drive traffic to your website.
          </p>

          <h2>Google Ads:</h2>
          <p>
            Google Ads is one of the most effective ways to reach potential
            customers actively searching for products or services like yours.
            Our Google Ads specialists create highly targeted ad campaigns to
            ensure your ads appear at the top of search results. We continuously
            monitor and optimize your campaigns to improve ad performance and
            reduce costs. With our expertise, you can achieve higher
            click-through rates (CTR) and conversions, ultimately driving more
            revenue for your business.
          </p>

          <h2>Instagram Marketing:</h2>
          <p>
            Instagram is a visual platform that offers unique opportunities for
            businesses to showcase their products and connect with customers.
            Our Instagram marketing services include content creation,
            influencer partnerships, and targeted advertising. We help you build
            a strong Instagram presence that attracts followers and drives
            engagement. By leveraging Instagram’s powerful features, such as
            shoppable posts and stories, we turn your followers into loyal
            customers.
          </p>
          <h2>Facebook Marketing:</h2>
          <p>
            Facebook remains a dominant force in social media marketing. Our
            Facebook marketing services encompass everything from page
            management and content creation to targeted ad campaigns and
            audience engagement. We utilize Facebook’s advanced targeting
            options to reach your ideal customers and drive meaningful
            interactions. Whether you’re looking to increase page likes,
            generate leads, or boost sales, our Facebook marketing strategies
            deliver tangible results.
          </p>
          <h2>Threads Marketing:</h2>
          <p>
            staying ahead of trends and leveraging the unique features of
            Threads, we help your brand stand out and capture the attention of
            your target audience. Partner with us to elevate your digital
            marketing efforts. Our comprehensive services are designed to drive
            growth, increase visibility, and achieve your business goals. Let us
            help you navigate the digital landscape and achieve lasting success.
          </p>
        </section>
    
        
      </Container>
    </div>
  );
};

export default Services_2;
