import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Contact } from "../../pages/contact";

const Services_8 = () => {
  return (
    <div className="services-container">
      <Container>
        <section className="services-header">
          <h1>
            Increase your Manufacturing speed with our Electronic Components
            Sales and Custom Manufacturing services
          </h1>
        </section>
        <section className="services-content">
          <ul>
            <li>
              <h2>
                Diverse Inventory of Electronic Components
                <p>
                  We specialize in selling a comprehensive inventory of
                  electronic components that cater to a wide range of
                  applications. Our selection spans from basic resistors and
                  capacitors to advanced transistors and integrated circuits
                  (ICs). We ensure that all our products meet stringent industry
                  standards, providing reliable performance for various
                  electronic projects. Whether you are working on a small-scale
                  project or a complex electronic system, we have the components
                  you need to bring your ideas to life.
                </p>
              </h2>
            </li>
          </ul>
          <h2>Surface-Mount Technology (SMT) Components </h2>
          <ul>
            <p>
              In the realm of modern electronics, Surface-Mount Technology (SMT)
              has become indispensable. We offer an extensive range of SMT
              components, including resistors, capacitors, diodes, and
              transistors. These components are essential for creating compact
              and efficient electronic designs, enabling the development of
              smaller and more powerful devices. Our SMT components are sourced
              from reputable manufacturers and are designed to meet the highest
              quality standards.
            </p>
          </ul>
          <h2>Custom Sensors, Modules, and Project Prototypes Manufacturing</h2>
          <h2>Specialized Components for Robotics and Drones</h2>
          <ul>
            <p>
              In addition to traditional electronic components, we are renowned
              for our expertise in parts and modules for robotics, drones, and
              specialized applications. Our product offerings include a wide
              range of sensors, motor controllers, communication modules, and
              other essential parts. These components are specifically designed
              to meet the unique needs of robotics and drone applications,
              ensuring that your projects achieve the desired functionality and
              performance.
            </p>
          </ul>
          <h2>Custom Manufacturing Capabilities </h2>
          <p>
            We understand that every project has unique requirements. To meet
            these needs, we offer custom manufacturing capabilities. Whether you
            need a specialized sensor for a unique application or a
            custom-designed module to integrate with your existing systems, our
            team can develop a solution that precisely fits your specifications.
            Our custom manufacturing process ensures that you receive exactly
            what you need for your project, without any compromises. This
            capability allows us to provide tailored solutions that enhance the
            performance and efficiency of your electronic systems.
          </p>
          <h2>Unwavering Commitment to Quality</h2>
          <br />
          <h2>Rigorous Quality Assurance </h2>
          <p>
            Our commitment to quality is unwavering. We source our components
            from reputable manufacturers and conduct rigorous testing to ensure
            every product meets our high standards. This dedication to quality
            guarantees that our customers receive reliable and durable
            components that perform as expected. We understand the critical role
            that quality plays in the success of any project, and we strive to
            provide products that you can trust. By maintaining strict quality
            control processes, we ensure that our components deliver consistent
            performance and long-term reliability.
          </p>
          <h1>Customer Satisfaction and Innovation</h1>
          <h2>Building Long-Term Relationships </h2>
          <p>
            Customer satisfaction is at the core of our business. We believe in
            building long-term relationships by providing exceptional products
            and services. Our knowledgeable and friendly team is always
            available to assist with product selection, technical support, and
            any other needs that may arise. We are committed to helping our
            customers succeed, from the initial concept through to the final
            product. Our customer-centric approach ensures that we understand
            and meet the specific needs of each client, fostering trust and
            loyalty.
          </p>

          <h2>Driving Innovation </h2>
          <p>
            Innovation drives our success. We continuously explore new
            technologies and develop products that keep pace with the rapidly
            evolving electronics industry. Our research and development team is
            dedicated to creating cutting-edge solutions that meet the changing
            needs of our customers. By staying at the forefront of technological
            advancements, we ensure our products remain relevant and
            competitive. Our commitment to innovation enables us to offer
            advanced solutions that push the boundaries of what’s possible in
            electronics, robotics, and drone technology.
          </p>
          <h1>Partner with Us</h1>
          <h2>Access to Expertise and Excellence </h2>
          <p>
            Choosing us as your partner gives you access to our extensive
            expertise and dedication to excellence. We are committed to helping
            you turn your ideas into reality with our high-quality components
            and customized solutions. Whether you are a DIY enthusiast working
            on a hobby project or a professional engineer tackling a complex
            design, we provide the support and resources you need to succeed.
            Our comprehensive solutions are tailored to meet the specific needs
            of your project, from standard components to custom prototypes and
            sensors.
          </p>
         
          <h2>Advancing Technology Together </h2>
          <p>
          Together, we can push the boundaries of what’s possible in electronics, robotics, and drone technology. Our collaborative approach ensures that we work closely with our clients to understand their unique challenges and provide innovative solutions that drive success. By partnering with us, you benefit from our deep industry knowledge, technical expertise, and unwavering commitment to quality. Let us help you achieve your goals and bring your innovative ideas to life.
          </p>
     <h1>Our Mission: Empowering Innovation in Electronics, Embedded Systems, AI, and IoT</h1>
          <p>
          With a rich history spanning over 25 years, we have been instrumental in advancing electronic design, embedded systems, circuitry, PCB (Printed Circuit Board) design, robotics, automation, Artificial Intelligence (AI), and Internet of Things (IoT) technologies throughout India. Our journey is marked by pioneering developments aimed at pushing technological boundaries and fostering a culture of innovation and indigenous manufacturing capabilities.
          </p>

          <h2>Innovation and Development:</h2>
          <p>
          Our commitment to innovation led us to develop Microstudio IDE, a revolutionary microcontroller code editor resulting from 12 years of intensive research. This platform supports a wide array of microcontrollers including 8051, 8052, STM, PIC, PDK, AVR, and more, encompassing over 5500 supported variants. What distinguishes Microstudio IDE is its unified coding structure, ensuring approximately 95% code uniformity across all supported microcontrollers. This consistency, coupled with built-in open-source libraries and examples, accelerates development and ensures precision in programming tasks.
          </p>
          <h2>Empowering with AI and IoT:</h2>
          <p>
          In addition to our core strengths in electronics and embedded systems, we are pioneering advancements in AI and IoT. These transformative technologies are reshaping industries and unlocking new possibilities. By integrating AI capabilities into our solutions, we enhance automation, decision-making processes, and system intelligence. Our IoT initiatives focus on connecting devices and leveraging data for smarter, more efficient operations across diverse domains.
          </p>
      
        </section>
        <Contact/>

      </Container>
    </div>
  );
};

export default Services_8;
